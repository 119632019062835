
import React from "react";
import { useState } from "react";
import Webcam from "react-webcam";
import {
  Box,
  VStack,
  Button,
  Center,
  Text
} from '@chakra-ui/react';
// import { useHistory } from "react-router-dom";
import { uploadFileVoucher } from "../utils/ApiUtilities";
// import VoucherResults from "./VoucherResults";
import { FaCamera, FaHome } from "react-icons/fa";
import Loading from "../utils/Loading";
import { ButtonBox } from "./HomeButton";
import * as CONFIG from "../config";
import { useSelector } from "react-redux";
import { format } from 'date-fns'

function CapturePhoto(props) {
  // console.log('CapturePhoto');
  const { selectedVoucher, whenList } = useSelector((state) => state.user);
  // console.log('whenList=' + format(whenList, 'dd/MM/yyyy'));
  // history.push(HOME_URL + (selectedVoucher !== false && selectedVoucher));
  const { updateResults, setImage } = props;
  // console.log('updateResults', updateResults);
  // const history = useHistory();

  // const [aquired, setAquired] = useState(false);
  const [elaborated, setElaborated] = useState(false);
  const [results, setResults] = useState(false);
  const [loading, setLoading] = useState(false);
  // const [scanImage, setScanImage] = useState(null);
  // const [webCamobj, setWebCamobj] = useState(false);

  // const [deviceId, setDeviceId] = React.useState({});
  // const [devices, setDevices] = React.useState([]);

  // const handleDevices = React.useCallback(
  //   mediaDevices =>
  //     setDevices(mediaDevices.filter(({ kind }) => kind === "videoinput")),
  //   [setDevices]
  // );

  // React.useEffect(
  //   () => {
  //     navigator.mediaDevices.enumerateDevices().then(handleDevices);
  //     console.log('devices', devices[0])
  //   },
  //   [handleDevices]
  // );

  // return (
  //   <>
  //     {devices.map((device, key) => (
  //       <div>
  //         <Webcam audio={false} videoConstraints={{ deviceId: device.deviceId }} />
  //         {device.label || `Device ${key + 1}`}
  //       </div>

  //     ))}
  //   </>
  // );
  const videoConstraints = CONFIG.cameraVideoConstraints;
  const webcamRef = React.useRef(null);
  const capture = React.useCallback(
    async () => {
      setLoading(true);
      // setAquired(true);
      // const imageSrc = webcamRef.current.getScreenshot({ width: 561, height: 747 });
      // const imageSrc = webcamRef.current.getScreenshot({ width: 320, height: 426 });
      const imageSrc = webcamRef.current.getScreenshot({ width: CONFIG.captureWidth, height: CONFIG.captureHeight });
      // const imageSrc = webcamRef.current.getScreenshot();
      setImage(imageSrc);
      // console.log('imageSrc', imageSrc);
      // uploadFileVoucher(imageSrc);
      // setResults(await uploadFileVoucher(imageSrc));
      // console.log('updateResults', updateResults);
      const res = await uploadFileVoucher(imageSrc, 0, format(whenList, 'dd/MM/yyyy'));
      // console.log('res', res);
      setResults(res)
      res.success ? updateResults !== undefined && updateResults(res) : setLoading(false);
      // updateResults !== undefined ? updateResults(await uploadFileVoucher(imageSrc)) : setResults(await uploadFileVoucher(imageSrc));
      // res.success ? updateResults !== undefined ? updateResults(res) : setResults(res) : setLoading(false);
      // console.log('res', res)
      // localStorage.vouchercode = undefined;
      setElaborated(res.success);
      
      // const copyResults = results;
      // copyResults.message = "CHECK-IN COMPLETATO!"
      // setResults(copyResults);
      // setTimeout(function () {
      //   history.push("/");
      // }, 1500);
    },
    [updateResults, webcamRef]
  );

  // React.useEffect(
  //   () => {
  //     setLoading(true);
  //     const webCamp = <Webcam
  //       audio={false}
  //       // height={320}
  //       ref={webcamRef}
  //       screenshotFormat="image/png"
  //       // width={1280}
  //       videoConstraints={videoConstraints}
  //       style={{ width: "100%", objectPosition: "center" }}
  //     />;
  //     setWebCamobj(webCamp);
  //     setLoading(false);
  //   },
  //   []
  // );

  // const handlePushBarcode = () => {
  //   history.push("/barcode");
  // }

  // const handlePushQrcode = () => {
  //   history.push("/qrcode");
  // }
  return !loading ? (
    <>
      <VStack>
        <Box
          border={'0px'}
          mt={'10px'}
        >
          {results ? results.success ? <Text color={'green'} lineHeight={'1.5em'} fontWeight={'bold'} >{results.message.length > 0 && results.message[0]}</Text> : <Text mb={'10px'} color={'red'} lineHeight={'1.5em'} fontWeight={'bold'}>{results.error[0]}</Text> : <></>}
          {/* {devices.map((device, key) => (
            <div>
              <Webcam audio={false} videoConstraints={{ deviceId: device.deviceId }} />
              {device.label || `Device ${key + 1}`}
            </div>

          ))} */}
          <Center>
            {/* {webCamobj} */}
            <Webcam
              audio={false}
              // height={320}
              ref={webcamRef}
              screenshotFormat="image/png"
              // width={1280}
              videoConstraints={videoConstraints}
              style={{ width: "100%", objectPosition: "center" }}
            />
            </Center>
        </Box>
        <Box mt={'-80px'} >
          <Button
            onClick={capture}
            mt={'-82px'}
            // mb={'10px'}
            colorScheme='orange'
            size='lg'
            variant='orange'
            leftIcon={<FaCamera />}
            fontSize={'1em'}
          >
            Completa
          </Button>
        </Box>
        {/* <Box>
          <Button onClick={handlePushBarcode}>
            Barcode
          </Button>
          <Button onClick={handlePushQrcode}>
            Qrcode
          </Button>
        </Box> */}
      </VStack>
    </>
  ) : (
    // elaborated === true ? <VoucherResults results={results} elaborated={elaborated} /> : <Loading label='Loading...' size='120px' />
    elaborated === true ?
      <>
        {results.success ? <Text color={'green'} lineHeight={'1.5em'} fontWeight={'bold'} >{results.message.length > 0 && results.message[0]}</Text> : <Text mb={'10px'} color={'red'} lineHeight={'1.5em'} fontWeight={'bold'}>{results.error.length > 0 && results.error[0]}</Text>}
          {results.fileUploaded ?
          <>
            {!results.success && <ButtonBox title={CONFIG.BUTTON_ORPHAN} icon={<FaCamera size={'1em'} />} fontSize={'1em'} link={CONFIG.VOUCHER_ORPHAN_PHOTO} descText={CONFIG.NOTE_ORPHAN} />}
            <ButtonBox title={'Home'} icon={<FaHome size={'2em'} />} fontSize={'2em'} link={CONFIG.HOME_URL} paddingBottom={'15px'} />
          </>
          :
          <></>
        }
      </>
      :
      <>
        {/* {updateResults == undefined && <Text color={'green'} lineHeight={'1.5em'} fontWeight={'bold'} >Upload immagine in corso</Text>} */}
          <Text color={'orange'} lineHeight={'1.5em'} fontWeight={'bold'} >Upload immagine in corso</Text>
        <Loading label='Loading...' size='120px' />
      </>
  );

}

export default CapturePhoto;
